<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12 lg10>
        <v-sheet color="white" elevation="20" class="pa-2">
          <v-row class="pt-2 pb-2">
            <v-col cols="12" md="6">
              <v-card height="100%" elevation="0">
                <v-card-title> Reporte de Caja </v-card-title>
                <v-card-text>
                  <b>{{ this.store.PartyName }}</b>
                  <br />
                  {{ this.store.store_address + " , " + this.store.store_city }}<br />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card :loading="loading_status" loader-height="10" flat>
                <v-card-text>
                  <v-text-field
                    v-model="issueDate"
                    label="Fecha"
                    required
                    dense
                    outlined
                    type="date"
                    @input="get_report"
                  />
                  <v-select
                    v-model="vendor"
                    :items="vendors"
                    return-object
                    item-value="code"
                    item-text="name"
                    label="Cajero"
                    placeholder="Cajero"
                    outlined
                    dense
                    @change="get_report"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers_cart"
                :items="cart"
                :items-per-page="-1"
                class="elevation-1"
                dense
                calculate-widths
                mobile-breakpoint="0"
                item-key="ean"
                :loading="loading_status"
                loader-height="10"
                loading-text="Cargando ..."
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Productos</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-btn fab icon @click="editItem(item)">
                    {{ item.cantidad }}
                  </v-btn>
                </template>

                <template v-slot:[`item.precio`]="{ item }">
                  {{ "$ " + (item.precio * item.cantidad).toLocaleString(2) }}
                  <br />
                  <small
                    class="red--text text-decoration-line-through"
                    v-if="item.discount > 0"
                    >{{
                      "$ " +
                      ((item.discount + item.precio) * item.cantidad).toLocaleString(2)
                    }}
                  </small>
                </template>

                <template slot="body.append">
                  <tr>
                    <th></th>
                    <th>Totales</th>
                    <th class="text-center">
                      {{ sumField(cart, "item_quantity").toLocaleString("es") }}
                    </th>
                    <th class="text-right">
                      {{ sumField(cart, "item_price").toLocaleString("es") }}
                    </th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="6">
              <v-data-table
                :headers="headers_payments"
                :items="payments"
                item-key="id"
                hide-default-footer
                mobile-breakpoint="0"
                sort-by="name"
                class="elevation-1"
                dense
                :loading="loading_status"
                loader-height="10"
                loading-text="Cargando ..."
              >
                <template #top>
                  <v-toolbar flat>
                    <v-toolbar-title>Formas de pago</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>

                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th class="text-right">
                      {{ sumField(payments, "payment_value").toLocaleString(2) }}
                    </th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" sm="12">
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn
                  class="right-input"
                  color="success"
                  :disabled="validate_doc()"
                  x-large
                  @click="save_invoice"
                >
                  Guardar
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-sheet>
      </v-flex>
    </v-layout>

    <docViewer
      :item="item"
      :dialog="dialog"
      :newInvoice="nInvoice"
      @close="dialog = false"
      @refresh="refresh_view()"
    />
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import createClient from "../utils/createClient.js";
import createPayment from "../utils/createPayment.js";
import dianResponse from "../utils/dianResponse.js";
import docViewer from "../components/doc_viewer_report.vue";
import createDoc from "../utils/create_doc.js";
import settings from "../utils/settings.js";
import createCompany from "../utils/createCompany.js";

export default {
  name: "Home",
  components: { docViewer },
  beforeMount() {
    this.get_data_company();
  },
  mounted() {
    //document.getElementById("sku").focus();
    this.store = window.store;
    if (this.$store.getters.profile.user_access == "USUARIO") {
      this.vendor = {
        code: this.$store.getters.profile.user_email,
        name: this.$store.getters.profile.name,
      };
      this.vendors = [this.vendor];
      console.log(this.vendor, this.vendors);
    } else {
      this.get_cajeros();
    }

    this.get_report();
  },
  data() {
    return {
      issueDate: getToday(),
      company: createCompany(),
      doctype: "RPC",
      nInvoice: false,
      store: createClient(),
      connection: null,
      loading_status: false,
      loading_vendors: false,
      itemEdit: createItem(),
      editvalid: false,
      skuError: false,
      timeout: 250,
      timeout_error: 2000,
      edit_dialog: false,
      valid: false,
      productAdded: false,
      payvalid: false,
      search_valid: false,
      client_dialog: false,
      payment_dialogo: false,
      search_dialog: false,
      showPrint: false,
      filters: { categoria: "", marca: "", refe: "", id_pos: "" },
      sku: null,
      cart: [],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: {
        codigo: "01",
        valor: "EFECTIVO",
      },
      payment: createPayment(),
      topay: true,
      discoutRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 50) return true;
        return "Debe ser un numero entre 0 y 50";
      },
      requiredRule: [(v) => (v && v !== null) || "Forma de pago requerida"],
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
      client: createClient(),
      setting: settings(),
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      doctypes: [{ value: "RPC", label: "Reporte de Caja" }],
      FP: null,
      CT: null,
      MR: null,
      colors: [],
      headers: {
        false: [
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "categoria",
            dataType: "text",
          },
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "grupo",
            dataType: "text",
          },

          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "marca",
            dataType: "text",
          },

          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "precio",
            dataType: "number",
          },
          {
            text: "Descuento",
            align: "end",
            sortable: true,
            value: "descuento",
            dataType: "number",
          },
          { text: "", value: "actions", sortable: false },
        ],
        true: [
          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "marca",
            dataType: "text",
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "precio",
            dataType: "number",
          },
        ],
      },
      headers_cart: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "item_code",
          dataType: "text",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "item_concept",
          dataType: "text",
        },
        {
          text: "Qty",
          align: "center",
          sortable: true,
          value: "item_quantity",
          dataType: "text",
          width: "10",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "item_price",
          dataType: "number",
        },
      ],
      headers_payments: [
        {
          text: "Concepto",
          align: "start",
          value: "payment_concept",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
          dataType: "number",
        },
      ],
      inventory_lst: [],
      vendors: [],
      vendor: { code: null, name: "Todos" },
      totals: { qty: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      bags: 0,
      discount: null,
      dataURL: null,
      invoiceImg: null,
      st_items: [],
      itemsby: null,
      menus: null,
      menu_selected: null,
      menuName: null,
      st_dialog: false,
      eInvoice: null,
      seller: null,
      dian_dialog: false,
      dian_response: dianResponse(),
      docview: "",
      dialog: false,
      item: createDoc(),
      validations: [
        { status: false, label: "Deme suministrar nombre del cliente" },
        { status: false, label: "Correo electrónico es requerido" },
        { status: false, label: "Documento de identificación del Cliente es requerido" },
        { status: false, label: "El tipo de documento es requerido" },
        { status: false, label: "Vendor es requerido" },
        { status: false, label: "Requiere Factura Electrónica" },
        { status: false, label: "Valor" },
        { status: false, label: "Ciudad es requerido" },
      ],
      validForm: false,
      qtyRules: [
        (v) => !!v || "Cantidad es requerida",
        (v) => (v && v >= 0) || "No puede ser cero",
      ],
    };
  },
  methods: {
    validate_doc() {
      if (this.cart.length > 0 && this.payments.length > 0 && this.vendor.code !== null) {
        return false;
      } else {
        return true;
      }
    },
    async get_cajeros() {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
      };
      console.log("QRY", qry);
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_cajeros",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });

      let data = await promise;

      console.log("get_cajeros", data);
      this.vendors = data;

      this.loading_status = false;
    },
    async get_report() {
      this.loading_status = true;
      console.log(this.vendor);
      var vendedor = "";
      if (this.$store.getters.profile.user_access == "USUARIO") {
        vendedor = this.$store.state.profile.email;
      } else {
        if (this.vendor.code) {
          vendedor = this.vendor.code;
        } else {
          vendedor = "";
        }
      }

      var qry = {
        store: window.store.store_id,
        date: this.issueDate,
        userId: vendedor,
      };

      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_cashdrawer_report",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });

      let data = await promise;

      console.log(data);

      this.loading_status = false;

      this.cart = data.items;
      var pays = data.payments;
      var result = [];
      this.updateTotal();
      pays.reduce(function (res, value) {
        if (!res[value.payment_concept]) {
          res[value.payment_concept] = {
            payment_concept: value.payment_concept,
            payment_code: value.payment_code,
            payment_reference: null,
            payment_value: 0,
          };
          result.push(res[value.payment_concept]);
        }
        res[value.payment_concept].payment_reference = value.payment_reference;
        res[value.payment_concept].payment_value += parseFloat(value.payment_value);
        return res;
      }, {});

      result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      this.payments = result;
      console.log("=====", result);
    },
    validateID(e) {
      if (e.keyCode === 13) {
        this.get_rut();
      }
    },
    clearClient() {
      this.client = createClient();
      this.client.CityName = this.company.city_code;
    },
    get_data_company() {
      var qry = {
        store: window.store.store_id,
        table: "f_companies",
        filters: [
          {
            field: "store_code",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "PartyIdentification",
            operator: "=",
            value: window.store.store_business_id,
          },
        ],
      };
      this.loading_status = true;

      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        //console.log("get_data_company", data);
        if (data.length > 0) {
          this.company = data[0];
          this.client.CityName = this.company.city_code;
        }
      });
    },
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    sumFieldPrice(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    scroll() {
      document.getElementById("scroll-target").scrollTop = document.getElementById(
        "scroll-target"
      ).scrollHeight;
    },
    editItem(e) {
      this.itemEdit = e;
      this.edit_dialog = true;
    },
    itemUpdate(e) {
      e.preventDefault();
      this.$refs.editform.validate();
      if (this.editvalid) {
        console.log("Saving");
      }
    },
    get_name_typeId(e) {
      var index = this.setting["type_document_identifications"].findIndex(
        (itm) => itm.id === e
      );
      if (index >= 0) {
        return this.setting["type_document_identifications"][index].name;
      } else {
        return "";
      }
    },
    get_name_city(e) {
      var index = this.setting["municipalities"].findIndex((itm) => itm.id === e);
      if (index >= 0) {
        return this.setting["municipalities"][index].name;
      } else {
        return "";
      }
    },
    restQty() {
      if (this.bags > 0) {
        this.bags = this.bags - 1;
      }
    },
    addQty() {
      this.bags = this.bags + 1;
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    delete_payment(e) {
      var index = this.payments.indexOf(e);
      if (index !== -1) {
        this.payments.splice(index, 1);
      }
      this.updateTotal();
    },
    delete_item(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }

      this.updateTotal();
    },
    addPayment(e) {
      e.preventDefault();
      if (this.pay_mode) {
        if (this.pay_mode.codigo === "01") {
          if (this.payment.payment_value > this.totals.unpaid) {
            var cmb =
              parseFloat(this.payment.payment_value) - parseFloat(this.totals.unpaid);
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payment.payment_value = this.totals.unpaid;
            this.payments.push({ ...this.payment });

            var cambio = {
              payment_concept: "EFECTIVO",
              payment_code: "01",
              payment_reference: " Cambio $ " + cmb,
              payment_value: 0,
            };
            this.payments.push({ ...cambio });
            this.updateTotal();
          } else {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payments.push({ ...this.payment });
            this.updateTotal();
          }
          this.pay_mode = {
            codigo: "01",
            valor: "EFECTIVO",
          };
        } else {
          if (this.payment.payment_value <= this.totals.unpaid) {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payments.push({ ...this.payment });
            this.updateTotal();
            this.pay_mode = {
              codigo: "01",
              valor: "EFECTIVO",
            };
          } else {
            this.payValue = true;
            this.payment.payment_value = 0;
          }
        }
      }
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },

    updateTotal() {
      this.totals.qty = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      this.cart.forEach((itm) => {
        this.totals.qty += parseFloat(itm.cantidad);
        this.totals.total += parseFloat(itm.cantidad) * parseFloat(itm.precio);
        this.totals.tax +=
          (parseFloat(itm.cantidad) * parseFloat(itm.precio) * parseFloat(19)) / 100;
      });
      this.payments.forEach((itm) => {
        this.totals.paid += parseFloat(itm.payment_value);
      });
    },
    get_vendors() {
      var qry = {
        store: window.store.store_id,
      };
      this.loading_vendors = true;
      webserver("vendors", qry, (data) => {
        this.loading_vendors = false;
        console.log(data);
        data.forEach((itm) => {
          itm.nombre = itm.nombre.toUpperCase();
        });
        window.vendors = data;
        this.vendors = data;
      });
    },

    resetInvoice() {
      this.filters = { categoria: "", marca: "", refe: "", id_pos: "" };
      this.bags = 0;
      this.cart = [];
      this.client = createClient();
      this.payment = createPayment();
      this.payments = [];
      this.client.CityName = this.company.city_code;

      this.updateTotal();
    },
    changeMenu(e) {
      this.menu_selected = this.itemsby[e];
      console.log(this.menu_selected);
      this.menuName = e;
    },
    get_options(e) {
      return this.groupBy(e.split(","));
    },
    groupBy(arr) {
      var group = [];
      var option = { ean: null, description: null };
      for (let i = 0; i < arr.length - 1; i += 3) {
        console.log(arr[i]);
        var color = "";
        if (this.colors[arr[i + 1]]) {
          color = this.colors[arr[i + 1]].valor;
        } else {
          color = arr[i + 1];
        }
        option = { ean: arr[i], description: color + " - " + arr[i + 2] };
        group.push(option);
        //console.log(option);
      }
      return group;
    },
    save_invoice() {
      this.updateTotal();
      var cajero = {
        PartyIdentification: "",
        PartyName: this.vendor.name,
        PartyEmail: this.vendor.code,
      };

      var invoice = {
        buyer: cajero,
        doc: {
          doc_type: this.doctype,
          doc_value: this.sumField(this.cart, "item_price"),
          store: window.store.store_id,
          userId: window.profile.user_email,
          vendor: this.vendor.code,
        },
        items: this.cart,
        payments: this.payments,
        seller: window.seller,
        store: window.store.store_id,
      };
      console.log("post_doc", invoice);
      this.loading_status = true;
      webserver("post_doc", invoice, (data) => {
        this.loading_status = false;
        this.item = { ...data };
        this.dialog = true;
        this.nInvoice = true;
        this.resetInvoice();
        this.loading_vendors = false;
      });
    },
    refresh_view() {
      var e = this.item;
      var issudate = e.issuedate.split(" ");
      var doc = {
        store: e.store,
        issueDate: issudate[0],
        did: e.doc_number,
      };
      this.loading_status = true;
      webserver("get_invoice_fe", doc, (data) => {
        this.loading_status = false;
        this.item = data;
        this.dialog = true;
        this.loading_vendors = false;
      });
    },
  },

  watch: {
    bags: function () {
      var idx = this.payments.findIndex((itm) => itm.payment_code === "00");
      if (idx === -1) {
        this.payments.push({
          payment_code: "00",
          payment_concept: "ICB ley 1819 de 2016",
          payment_reference: "",
          payment_value: 66 * this.bags,
        });
      } else {
        this.payments[idx].payment_value = 66 * this.bags;
        if (this.bags <= 0) {
          this.payments.splice(idx, 1);
        }
      }
      idx = this.payments.findIndex((itm) => itm.payment_code === "000");
      if (idx === -1) {
        this.payments.push({
          payment_code: "000",
          payment_concept: "Descuento Impuesto Bolsas",
          payment_reference: "",
          payment_value: -66 * this.bags,
        });
      } else {
        this.payments[idx].payment_value = -66 * this.bags;
        if (this.bags <= 0) {
          this.payments.splice(idx, 1);
        }
      }

      if (this.bags === 0) {
        idx = this.payments.findIndex((itm) => itm.payment_code === "00");
        this.payments.splice(idx, 1);
        idx = this.payments.findIndex((itm) => itm.payment_code === "000");
        this.payments.splice(idx, 1);
        this.updateTotal();
      }
    },

    pay_mode() {
      if (this.pay_mode.codigo === "01") {
        this.payRefe = false;
      } else {
        this.payRefe = true;
      }
      //console.log(this.payRefe);
    },
    payment(e) {
      console.log(e);
    },
    st_items: function (val) {
      var res = val.reduce(function (r, a) {
        r[a.category] = r[a.category] || [];
        r[a.category].push(a);
        return r;
      }, Object.create(null));
      this.itemsby = res;
      this.menus = Object.keys(res);
      if (this.menuName) {
        this.menu_selected = res[this.menuName];
      } else {
        this.menu_selected = res[Object.keys(res)[0]];
      }
    },
  },
  updated() {
    //this.scroll();
  },
  computed: {},
};
</script>

<style lang="scss">
.bg-pos {
  //  background: url("../assets/login.jpg") no-repeat center center fixed;
  background: #cfd8dc;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.fixed-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.center-input input {
  text-align: center;
  width: 10px;
}

.right-input input {
  text-align: right;
  width: 10px;
}

.scroll {
  overflow-y: scroll;
  max-height: 90vh;
}

.cart-list {
  flex-grow: 1;
  overflow: auto;
  height: 60vh;
}
</style>
